import { useState, useEffect, useRef } from 'react';
import { genUUID } from "./Misc.mjs";
import { sendLogs } from '../campaignActions';

const logCollectingCampaigns = ['itD5SGvBqSWqO5qkIKeY', '2RasQn9RapcmBfdbVgpC', 'hPSpxyn2mQLgEeqfQqa4', 'XDaBNBvuqrD7Jw70N2Kl', 'pxYhIDJ3nAZ0ppKCZLce', 'CkYDNrwjZPJqs2DjCzNP', 'Focaphg4gEUycfwgvoXQ'];

function useLogger(initialCampaignId) {
    const userId = useRef(null);
    const pageId = useRef(null);
    const intervalRef = useRef(null);
    const campaignId = useRef(initialCampaignId ? initialCampaignId : null);
    const [deviceId, setDeviceId] = useState(JSON.parse(localStorage.getItem('deviceId')) || null);
    const logCollectorStarted = useRef(false);
    const shouldCollect = logCollectingCampaigns.includes(campaignId.current)
    
    function setLocalStorageValue(key, value){
        localStorage.setItem(key, JSON.stringify(value));
    }
    
    function setLoggerUserId(uid){
        return new Promise((resolve, reject) => {
            userId.current = uid;
            resolve()
        })
    }
    function setLoggerPageId(pid){
        return new Promise((resolve, reject) => {
            pageId.current = pid;
            resolve()
        })
    }
    
    const addLog = (type, elementId=null, payload) => {
        if(!shouldCollect) return;

        const actionId = genUUID();
        const time = new Date().getTime();
        const logPayload = {
            "campaignId": campaignId.current,
            "time": time,
            "userId": userId.current,
            "deviceId": deviceId,
            "uuid": actionId,
            "type": type,
            "pageId": pageId.current,
            "elementId": elementId,
            "message": payload,
            
        }
        localStorage.setItem(`unsent_${campaignId.current}_${deviceId}_${userId.current}_${actionId}_${time}`, JSON.stringify(logPayload))
        return logPayload
    }
    
    function getItemsWithPrefix(prefix) {
        let matchingItems = {};
        for (let i = 0; i < localStorage.length; i++) {
            let key = localStorage.key(i);
            if (key.startsWith(prefix)) {
                let value = localStorage.getItem(key);
                matchingItems[key] = value;
            }
        }
        return matchingItems;
    }
    
    const getLogs = (status) => {
        const logs = getItemsWithPrefix(status+'_'+campaignId.current);
        return logs;
    }
    
    const startLogCollector = () => {
        if(!shouldCollect) return;

        if (!logCollectorStarted.current) {
            intervalRef.current = setInterval(() => {
                let sentLogs = getLogs('sent');
                let unsentLogs = getLogs('unsent');
                if(sentLogs){
                    Object.keys(sentLogs).forEach((log) => {
                        localStorage.removeItem(log);
                    })
                }
                if(unsentLogs){
                    const readyToBeSent = Object.keys(unsentLogs).map((logKey) => {
                        const value = JSON.parse(unsentLogs[logKey]);
                        setLocalStorageValue(logKey.replace('unsent', 'sent'), value);
                        localStorage.removeItem(logKey);
                        return value;
                    })
                    sendLogs(readyToBeSent);
                }

            }, 30000);
            logCollectorStarted.current = true;
        }
    };

    useEffect(() => {
        if(deviceId){
            setLocalStorageValue('deviceId', deviceId);
        }else{
            setDeviceId(genUUID());
        }
    }, [deviceId])
    
    useEffect(() => {
        return(() => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        })
    }, [])
    
    return [addLog, setLoggerUserId, startLogCollector, setLoggerPageId];
}

export {
    useLogger
}
