class UserAuth {
	constructor(token, onChange){
		this.token = null;
		this.data = null;
		this.onChange = null;
		this.setToken(token);
		this.setOnChange(onChange);
	}
	getToken(){
		return this.token;
	}
	setToken(token){
		if(token){
			this.token = token;
			this.data = JSON.parse(atob(token.split('.')[1]));
		}else{
			this.token = null;
			this.data = null;
		}
		if(this.onChange) this.onChange(this.getToken());
	}
	getData(){
		return this.data;
	}
	getUserId() {
		return this.data?.userId;
	}
	setOnChange(fn){
		if(fn===null||fn===undefined) this.onChange = null;
		else if (typeof fn === 'function') this.onChange = fn;
		else throw new Error('The onChange must be a null, undefined or a function got: ' + typeof fn);
	}
	isExpired(){
		if(!this.data) return false;
		return (this.data.exp*1000) < Date.now();
	}
	isActive(){
		if(!this.data) return false;
		return !this.isExpired();
	}
	isUser(userId){
		if(!userId||!this.data?.userId) return false;
		return userId === this.data.userId;
	}
}
export default UserAuth;
