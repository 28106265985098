import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, doc, getDocs, collection, query, orderBy, limit, startAfter, writeBatch, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { genUUID } from "./utils/Misc.mjs";
import { getStorage, ref, uploadBytesResumable, connectStorageEmulator } from "firebase/storage";
import UserAuth from "./UserAuth.mjs";

const HAS_LOCAL_STORAGE = _localStorageWorks();

const pathname = window.location.pathname;
const campaignId = pathname.split('/')[1];
const uAuth = new UserAuth(HAS_LOCAL_STORAGE?window.localStorage.getItem('29a11dc582ff973404a3e81711e2'):undefined, (token)=>{if(HAS_LOCAL_STORAGE) window.localStorage.setItem('29a11dc582ff973404a3e81711e2', token||"")});

const firebaseConfig = {
	apiKey: "AIzaSyBD_NtznWHLPV4JiSVbPLz8k6LBfiCNG90",
	authDomain: "morepolls-1cc1e.firebaseapp.com",
	projectId: "morepolls-1cc1e",
	storageBucket: "morepolls-1cc1e.appspot.com",
	messagingSenderId: "817940652447",
	appId: "1:817940652447:web:bd58c497105e805fa2ddaa"
};
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
if(process.env.NODE_ENV === 'development') connectAuthEmulator(auth, "http://localhost:28754");

const db = getFirestore(firebaseApp);
if(process.env.NODE_ENV === 'development') connectFirestoreEmulator(db, "localhost", 29479);

const functions = getFunctions(firebaseApp, "europe-west1");
if(process.env.NODE_ENV === 'development') connectFunctionsEmulator(functions, 'localhost', 28764);

const storage = getStorage();
if(process.env.NODE_ENV === 'development') connectStorageEmulator(storage, 'localhost', 9199);

const extractUserId = function(search){
	if(typeof search === 'string' && search.trim()){
		return new URLSearchParams(search).get('user_id');
	}
	return null;
}
const url_user_id = extractUserId(window.location.search);
/* Login manager */
const loggedInListeners = [];
function loggedIn() {
	if(loginStatus === 'LOGGED_IN') return;
	loginStatus = 'LOGGED_IN';
	let listener = null;
	while((listener = loggedInListeners.pop())){
		listener();
	}
}
let loginStatus = null;

async function logInAnonymously2(useMigration) {
	let needsNew = true;
	if(uAuth.isActive()){
		needsNew = false;
		if(url_user_id && !uAuth.isUser('::'+url_user_id+'::')) needsNew = true;
		if(!url_user_id && uAuth.getUserId().startsWith('::') && uAuth.getUserId().endsWith('::')) needsNew = true;
	}
	if(needsNew||useMigration){
		(useMigration?migrateToken():getAnonymousUserToken(url_user_id))
		.then(function ({token}) {
			uAuth.setToken(token);
			if(useMigration) auth.signOut();
			loggedIn();
		})
		.catch((error) => {
			let deviceId = localStorage.getItem('deviceId') || 'undefined';
			deviceId = deviceId.replaceAll('"', '');
			const userId = uAuth.getUserId() || 'undefined';
			const actionId = genUUID();
			const time = new Date().getTime();
			const logPayload = {
				"campaignId": campaignId || 'undefined',
				"time": time,
				"userId": userId,
				"deviceId": deviceId,
				"uuid": actionId,
				"type": 'error',
				"pageId": 'undefined',
				"message": `Failed to sign in ${JSON.stringify(error)}, Details: ${JSON.stringify(error.details)}`,
			}
			localStorage.setItem(`unsent_${campaignId}_${deviceId}`, JSON.stringify(logPayload))
			console.error('Failed to signin', error, error.details);
			loginStatus = 'ERROR';
		});
	}else{
		loggedIn();
	}
}

async function onceLogIn(cb) {
	if(loginStatus === 'LOGGED_IN') return cb();
	else{
		loggedInListeners.push(cb);
	}
}
let tryCount = 0;
onAuthStateChanged(auth, (user) => {
	if (user) {
		// Migrate to new auth
		logInAnonymously2(true);
	} else {
		if(tryCount<2) {
			logInAnonymously2();
		}
		tryCount++;
	}
});

function logout() {
	auth.signOut();
	uAuth.setToken(null);
	if(HAS_LOCAL_STORAGE) localStorage.removeItem('_as');
	window.location.reload();
}

async function getCampaign(campaignId){
	if(!campaignId) return Promise.resolve(undefined);
	return new Promise(function (resolve, reject) {
		onceLogIn(async function () {
			const dt = await httpsCallable(functions, 'app/getCampaignAndResponses/'+campaignId)({"token":uAuth.getToken()});
			const data = _handleResponseWithToken(dt.data);
			if(data) return resolve({
				"campaign": data.campaign,
				"responses": data.responses,
				"user_id": uAuth.getUserId(),
			});
			return resolve(undefined);
		})
	})
}

async function getAnonymousUserToken(customUserId) {
	const dt = await httpsCallable(functions, 'app/getAnonymousUserToken/'+(customUserId||''))({});
	return dt.data;
}
async function migrateToken() {
	const dt = await httpsCallable(functions, 'app/migrateToken')({});
	return dt.data;
}

async function fetchScratchTicket(campaignId, elementId) {
	const dt = await httpsCallable(functions, `app/getScratchTicket/${campaignId}/${elementId}`)({"token":uAuth.getToken()});
	return _handleResponseWithToken(dt.data);
}
async function getQuizResults(campaignId, pageId) {
	const dt = await httpsCallable(functions, `app/getQuizResults/${campaignId}/pages/${pageId}`)({"token":uAuth.getToken()});
	return _handleResponseWithToken(dt.data);
}

async function submitPage(campaignId, pageId, data) {
	const dt = await httpsCallable(functions, `app/vote/campaigns/${campaignId}/responses/${uAuth.getUserId()}/${pageId}`)({
		"token": uAuth.getToken(),
		"data": data,
	});
	return dt.data;
}
async function clearAnswers(campaignId) {
	const dt = await httpsCallable(functions, `app/clearAnswers/${campaignId}`)({"token":uAuth.getToken()});
	return _handleResponseWithToken(dt.data);
}

async function uploadTemporaryFile(imageName, file, onStateChange, onSuccess, onFail){
	const storageRef = ref(storage, `tmp/${uAuth.getUserId()}/${imageName}`);

	const uploadTask = uploadBytesResumable(storageRef, file);
	uploadTask.on('state_changed', onStateChange, onFail, onSuccess);
}

async function getPosts(campaignId, elementId, getAfter=null, count=50){
	if(!campaignId) return undefined;
	const campaignsRef = collection(db, "campaigns", campaignId, "mediaWalls", elementId, "posts");
	const batch = getAfter?await query(campaignsRef, orderBy("timestamp", "desc"), limit(count), startAfter(getAfter)):await query(campaignsRef, orderBy("timestamp", "desc"), limit(count));

	const out = [];
	const docs = await getDocs(batch);
	docs.forEach((doc)=>{
		out.push({"id": doc.id, "data": doc.data(), "doc": doc});
	});
	return out;
}

async function sendLogs(logs){
	let segment;
	while( (segment = logs.splice(0,200)).length ) {
		const batch = writeBatch(db);
		segment.forEach(({campaignId, time, userId, deviceId, uuid, type, ...other})=>{
			const dt = {campaignId, time, userId, deviceId, uuid, type, ...other};
			const path = ["campaignLogs", campaignId, 'logs', uuid];
			console.log('Adding:', dt, 'to', path.join('/'))
			const logRef = doc(db, ...path);
			batch.set(logRef, dt);
		});
		await batch.commit();
	}
}

function _handleResponseWithToken(data) {
	if(data.token) uAuth.setToken(data.token);
	return data.data;
}

function _localStorageWorks() {
	const test = 'test';
	try {
		localStorage.setItem(test, test);
		const ok = localStorage.getItem(test)===test;
		localStorage.removeItem(test);
		return ok;
	} catch (e) {
		return false;
	}
}

export {
	logout,
	getCampaign,
	submitPage,
	fetchScratchTicket,
	getQuizResults,
	uploadTemporaryFile,
	getPosts,
	sendLogs,
	clearAnswers,
};
