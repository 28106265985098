function CirclePercent ({size, lineWidth, percent, text}) {
	const innerSize = (size-(lineWidth*2));
	const halfSize = (size/2);
	const rotate = 180*percent;
	return (
		<span style={{direction: "inline-block", width: size+"px", height: size+"px", background: "#e6e2e7", borderRadius: "50%"}}>
			<div>
				<div style={{width: size+"px", height: size+"px", position: "absolute", borderRadius: "50%", clip: `rect(0px, ${size}px, ${size}px, ${halfSize}px)`, transform: `rotate(${rotate}deg)`}}>
					<div style={{width: size+"px", height: size+"px", position: "absolute", borderRadius: "50%", clip: `rect(0px, ${halfSize}px, ${size}px, 0px)`, backgroundColor: "#9e00b1", transform: `rotate(${rotate}deg)`}}></div>
				</div>
				<div style={{width: size+"px", height: size+"px", position: "absolute", borderRadius: "50%", clip: `rect(0px, ${size}px, ${size}px, ${halfSize}px)`}}>
					<div style={{width: size+"px", height: size+"px", position: "absolute", borderRadius: "50%", clip: `rect(0px, ${halfSize}px, ${size}px, 0px)`, backgroundColor: "#9e00b1", transform: `rotate(${rotate}deg)`}}></div>
				</div>
				<div style={{width: innerSize+"px", height: innerSize+"px", borderRadius: "50%", background: "#eff5f5", lineHeight: innerSize+"px", textAlign: "center", marginTop: lineWidth+"px", marginLeft: lineWidth+"px", position: "absolute", zIndex: 2, fontWeight: 700, fontSize: "2em"}}>{text}</div>
			</div>
		</span>
	)
}
function CustomCorrectAnswers({ pages, correctAnswers }) {
	const responses = JSON.parse(localStorage.getItem('_as'));
	let questions = 0;
	let correctQuestions = 0;
	let selectedAnswersMap = {};

	for(const pageId in responses){
		const pageResponses = responses[pageId]?.elements||{};
		for(const elementId in pageResponses){
			if(Array.isArray(pageResponses[elementId].value)){
				pageResponses[elementId].value.forEach((r=>{
					selectedAnswersMap[r] = {
						isCorrect: correctAnswers.includes(r),
					};
				}))
			}
		}
	}
	pages.forEach(page=>{
		page.elements.forEach(element=>{
			if(element.type === 'poll') {
				questions++;
				if(element?.options?.find((option)=>selectedAnswersMap[option.id]?.isCorrect)) correctQuestions++;
			}
		});
	});

	const percent = questions?(correctQuestions/questions):0;
	const percentText = (Math.round(percent*1000)/10)+'%';

	return (<div>
		<div className="text--center" style={{"fontSize":"1.3em"}}>
			<span style={{verticalAlign: "middle", marginLeft:"10px"}}><CirclePercent size={120} lineWidth={5} percent={percent} text={percentText}/></span>
			<br/>
			<span>Απαντήσατε σωστά σε {correctQuestions} / {questions} ερωτήσεις</span><br/>
			<span>Ευχαριστούμε για τη συμμετοχή</span>
		</div>
		{false && pages.map((page=>{
			return page.elements.map(element=>{
				if(element.type === 'poll'){
					return (<div key={element.id} className="poll poll__app">
					{element.question && <div key={Math.random()}className="poll__header" dangerouslySetInnerHTML={{ __html: element.question }} />}
						<div key={Math.random()} className="mtop--10 flex flex--column">
							{element?.options.map((option, index) => {
								const selected = !!selectedAnswersMap[option.id];
								const isCorrect = correctAnswers.includes(option.id);
								const isWrong = !selectedAnswersMap[option.id]?.isCorrect;
								return (<div key={option.id} className={`poll__element ${selected && 'poll__element-active'}`} style={{backgroundColor:isCorrect?'green':null}}>
									<span className="material-icons mright--10 checked" style={{color:isWrong?'red':null}}>{isWrong?"cancel":"task_alt"}</span>
									<span className="vm--align">{option.text}</span>
								</div>)
							})}
						</div>
					</div>)
				}
				return undefined;
			})
		}))}
	</div>)
}

export default CustomCorrectAnswers;
