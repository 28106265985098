export const hexToRGB = (hex) => {
    hex = hex.toLowerCase()
    // Remove the "#" character
    hex = hex.replace("#", "");

    // check for the length of hex input
    if (hex.length === 3) {
        // Expand hex code to 6 characters if the input is in the format of #RGB
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    } else if (hex.length === 4) {
        // Expand hex code to 8 characters if the input is in the format of #RGBA
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }

    // Convert the hex string to an array of integers
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // check for the presence of alpha channel if the input is in the format of RRGGBBAA
    let a = parseInt(hex.substring(6, 8), 16);
    a = isNaN(a) ? 1 : a / 255; //if the input is RRGGBB return a value of 1

    // Return an RGB object
    return `${r} ${g} ${b}`;
}
export const removeOpacityFromRGBA = (rgba) => {
    // Check if input is valid RGBA format
    if (!/^rgba?\(\d{1,3},\s*\d{1,3},\s*\d{1,3},\s*(0|1|0?\.\d+)\)$/.test(rgba)) {
        return null;
    }
    // Remove "rgba(" and ")" from the string
    const strippedString = rgba.slice(5, -1);
    // Split the string into individual color values and alpha
    const [r, g, b, a] = strippedString.split(',');
    // Combine the color values without commas
    const color = `${r.trim()} ${g.trim()} ${b.trim()}`;
    // Return the color string without opacity
    return color;
}