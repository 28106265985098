const CAMPAIGN1 = '5CyZF7MzpOUBCeKOb87M';
const CAMPAIGN2 = '92KRZyh5E3mNiM6yXCcd';
const CAMPAIGN1_CORRECT_ANSWERS = ['30c5a253a1ab4ff6a90e1610a62aa3a5', '06045b3b51704a888271df7c040d1a45', '6fc55e629f8e47339f0d3fc332ab8460',];
const CAMPAIGN2_CORRECT_ANSWER_PAGES = ["e240b68635d44957bcf5c8323ec26231", "d3b029019a0342858d920948eca099ab", "0126e7b746ec418b8cf2b493d9167e5e", "27ba15529afc46728d1648a07d3928f1", "230316ee10f34ee49421e7a805d5a105", "961e00c71380454c8448f70295d42130", "3619df9897a44b148ae966581dead73c", "fada53d8af734b71a0c2e485097a4713"];
const CAMPAIGN2_CORRECT_ANSWERS = ["110b7407b3324ace8a5283f4b40859c1", "01addd0309ff4834b32db1b6feb7d19c", "9a5c60a096854c91a3e92628392c81ea", "aba013861a8d40c987410f29fddfeaf2", "5a8226e6e64946d3ac8c66d262508a9b", "bada89a2a24148558779e367dd3d0888", "a8c0bbf7f4f94cde9445a143365ddeac", "0134c972e5004b18a348ad176e5c1d7c"];
const CAMPAIGN2_PAGE_TO_SKIP = '391c2c9a2891483bb8cfe6dc0fb68093';
const CAMPAIGN2_QUESTION_COUNT = 3;
export const CAMPAIGN2_INFO_PAGE= '0eff5640338047a7a00b478004005dab';

export function showsCorrectAnswerEndPage(campaignId) {
	return (campaignId === CAMPAIGN1) || (campaignId === CAMPAIGN2);
}
export function showsCorrectAnswer(campaignId) {
	return (campaignId === CAMPAIGN2);
}
export function getCorrectAnswers(campaignId) {
	switch(campaignId){
		case CAMPAIGN1:
			return CAMPAIGN1_CORRECT_ANSWERS;
		case CAMPAIGN2:
			return CAMPAIGN2_CORRECT_ANSWERS;
		default:
			return undefined;
	}
}
export function getCorrectCount(campaignId, responses) {
	let answeredCount = 0;
	let correctCount = 0;
	if(responses){
		for (let i = 0; i < CAMPAIGN2_CORRECT_ANSWER_PAGES.length; i++) {
			if(responses[CAMPAIGN2_CORRECT_ANSWER_PAGES[i]]?.elements) {
				answeredCount++;
				let gotCorrect = false;
				for(const elementId in responses[CAMPAIGN2_CORRECT_ANSWER_PAGES[i]].elements){
					if(responses[CAMPAIGN2_CORRECT_ANSWER_PAGES[i]].elements[elementId].value.includes(CAMPAIGN2_CORRECT_ANSWERS[i])) gotCorrect = true;
				}
				if(gotCorrect) correctCount++;
			}
		}
	}
	return [correctCount, answeredCount];
}
export function pickPages(campaignId, pages) {
	if(campaignId===CAMPAIGN2){
		const REMOVE_INDEX = 1;
		const toKeep = [];
		const questionPages = pages.splice(REMOVE_INDEX, 8);
		console.log('questionPages', questionPages);
		// keep answered
		const o = JSON.parse(localStorage.getItem('_as')||'{}');
		for(let i = questionPages.length-1; i>=0 ; i--){
			if(o[questionPages[i].id]){
				toKeep.push(questionPages[i]);
				questionPages.splice(i, 1);
			}
		}
		console.log('1@ toKeep', JSON.parse(JSON.stringify(toKeep)));
		while(toKeep.length<3){
			const index = Math.floor(Math.random() * questionPages.length);
			toKeep.push(questionPages[index])
			questionPages.splice(index, 1);
		}
		pages.splice(REMOVE_INDEX, 0, ...toKeep);
		console.log('2@', JSON.parse(JSON.stringify(toKeep)));
	}
}

export function pageToSkip(campaignId, responses) {
	let out = null;
	if(campaignId === CAMPAIGN2){
		let [correctCount, answeredCount] = getCorrectCount(campaignId, responses);

		const correctTime = true;
		const answeredAll = (answeredCount===CAMPAIGN2_QUESTION_COUNT);
		const allCorrect = correctCount===answeredCount;

		if(answeredAll && (!correctTime || !allCorrect)) return CAMPAIGN2_PAGE_TO_SKIP;
	}
	return out;
}